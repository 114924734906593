<template>
  <div class="grid grid-cols-3 gap-6 h-full overflow-hidden">
    <div class="settings-menu">
      <div
          v-for="entry in allowedSettingsEntries"
          :key="entry.key"
          class="settings-menu__item"
          :class="{'settings-menu__item--active': activeEntryKey === entry.key}"
          @click="activeEntryKey = entry.key"
      >
        {{ entry.title }}
      </div>
    </div>
    <div v-if="activeEntry?.component" class="col-span-2 h-full overflow-auto">
      <component :is="activeEntry.component"/>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";
import {getAllowedSettingsEntries} from "@/community_settings";

export default {
  mixins: [ModalNavigation, ModalFooterMixin],

  data() {
    return {
      allowedSettingsEntries: [],
      activeEntryKey: null,
    };
  },

  computed: {
    activeEntry() {
      return this.allowedSettingsEntries.find(({key}) => key === this.activeEntryKey);
    },
  },

  created() {
    this.allowedSettingsEntries = getAllowedSettingsEntries();
    this.activeEntryKey = this.allowedSettingsEntries?.[0]?.key;
  }
}
</script>

<style scoped>
.settings-menu {
  @apply flex flex-col h-full overflow-auto bg-graphite-200 p-4;

  &__item {
    @apply border px-3 py-2 text-xs text-black mb-2 cursor-pointer;

    &--active {
      @apply border-active-500 text-active-500;
    }
  }
}
</style>
